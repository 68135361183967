import { VContainer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('campaignDetail')))])])], 1), _vm._v(" "), _c('ContentArea', [_c('VerticalTable', {
    attrs: {
      "headers": [{
        text: _vm.$t('campaignName'),
        value: 'name'
      }, {
        text: _vm.$t('owner'),
        value: 'owner',
        half: true
      }, {
        text: _vm.$t('createAt'),
        getter: function getter(val) {
          return _vm.$date(val.created_at);
        },
        half: true
      }],
      "item": _vm.campaign,
      "dummy": !_vm.campaign
    }
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mt-6"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('destination')) + "\n      ")]), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: 'ステータス',
        value: 'get_status_display'
      }, {
        text: '氏名',
        value: 'contact_name'
      }, {
        text: '会社名',
        value: 'contact.account.name',
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'contact.department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'contact.job_title',
        empty: _vm.$t('notSet')
      }, {
        text: '所有者(担当者)',
        value: 'contact.owner.name'
      }, {
        text: '到着希望日/発行日',
        value: 'shipping_date'
      }, {
        text: 'QR反響/クリック',
        value: 'touched_at',
        empty: _vm.$t('none')
      }],
      "items": _vm.datatable_items,
      "item-key": "id",
      "options": _vm.tableOptions,
      "server-items-length": _vm.totalItems,
      "loading": _vm.loading.order
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      },
      "click:row": function clickRow(item, _, event) {
        return _vm.onClickItem(item, event);
      },
      "auxclick:row": function auxclickRow(event, row) {
        return _vm.onClickItem(row.item, event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.get_status_display",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ShippingStatusTag', {
          attrs: {
            "text": item.get_status_display,
            "status": item.status
          }
        })];
      }
    }, {
      key: "item.contact_name",
      fn: function fn(_ref2) {
        var _item$contact;
        var item = _ref2.item;
        return [item.contact ? _c('LinkText', {
          attrs: {
            "to": (_item$contact = item.contact) !== null && _item$contact !== void 0 && _item$contact.id ? "/contact/".concat(item.contact.id) : null
          }
        }, [_vm._v("\n            " + _vm._s(_vm.$fullName(item.contact)) + "\n          ")]) : _vm._e()];
      }
    }, {
      key: "item.contact.account.name",
      fn: function fn(_ref3) {
        var _item$contact2, _item$contact3;
        var item = _ref3.item;
        return [(_item$contact2 = item.contact) !== null && _item$contact2 !== void 0 && _item$contact2.account ? _c('LinkText', {
          attrs: {
            "text": item.contact.account.name,
            "to": item.contact.account.id ? "/accounts/".concat(item.contact.account.id) : null
          }
        }) : (_item$contact3 = item.contact) !== null && _item$contact3 !== void 0 && _item$contact3.crm_company_name ? [_vm._v("\n            " + _vm._s(item.contact.crm_company_name) + "\n          ")] : _vm._e()];
      }
    }, {
      key: "item.shipping_date",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [!item.is_egift || item.status === 9 ? [_vm._v("\n            " + _vm._s(_vm.$date(item.shipping_date)) + "\n          ")] : _c('div', {
          staticClass: "text-caption disabled-text--text"
        }, [_vm._v("\n            " + _vm._s(_vm.$t('notIssue')) + "\n          ")])];
      }
    }, {
      key: "item.touched_at",
      fn: function fn(_ref5) {
        var _item$order_line_item, _item$order_line_item2, _item$order_line_item3;
        var item = _ref5.item;
        return [item.touched_at ? [_vm._v("\n            " + _vm._s(_vm.$date(item.touched_at)) + "\n            "), _c('br'), _vm._v("\n            " + _vm._s(_vm.$time(item.touched_at)) + "\n          ")] : (_item$order_line_item = item.order_line_items) !== null && _item$order_line_item !== void 0 && (_item$order_line_item2 = _item$order_line_item[0]) !== null && _item$order_line_item2 !== void 0 && (_item$order_line_item3 = _item$order_line_item2.line_item_lp) !== null && _item$order_line_item3 !== void 0 && _item$order_line_item3.opened_at ? [_vm._v("\n            " + _vm._s(_vm.$date(item.order_line_items[0].line_item_lp.opened_at)) + "\n            "), _c('br'), _vm._v("\n            " + _vm._s(_vm.$time(item.order_line_items[0].line_item_lp.opened_at)) + "\n          ")] : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mt-6"
  }, [_vm._v(_vm._s(_vm.$t('delivery')))]), _vm._v(" "), _c('VerticalTable', {
    staticClass: "mt-4",
    attrs: {
      "headers": [{
        text: _vm.$t('touch'),
        value: 'touch'
      }, {
        text: _vm.$t('cost'),
        value: 'cost'
      }, {
        text: _vm.isDigitalGift ? _vm.$t('issueDate') : _vm.$t('desiredArrivalDate'),
        value: 'desiredArrivalOrIssueDate'
      }, {
        text: _vm.$t('wowLink'),
        value: 'wowLink',
        disabled: !_vm.isDigitalGift
      }]
    },
    scopedSlots: _vm._u([{
      key: "item.touch",
      fn: function fn() {
        var _vm$lineItem;
        return [_c('TouchSummary', {
          attrs: {
            "item": _vm.lineItem,
            "link": ((_vm$lineItem = _vm.lineItem) === null || _vm$lineItem === void 0 ? void 0 : _vm$lineItem.touch) != null,
            "dummy": !_vm.campaign
          }
        })];
      },
      proxy: true
    }, {
      key: "item.cost",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-body-1"
        }, [_vm.orderPrice && _vm.totalItems ? _c('span', [_vm._v("\n              " + _vm._s("".concat(_vm.$price(_vm.orderPrice), " x ").concat(_vm.$t('persons', [_vm.totalItems]), " = ")) + "\n            ")]) : _vm._e(), _vm._v(" "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$price(_vm.totalPrice)))]), _vm._v(" "), _c('span', {
          staticClass: "text-caption secondary-text--text ml-2"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('destinationNotes')) + "\n            ")])])];
      },
      proxy: true
    }, {
      key: "item.wowLink",
      fn: function fn() {
        return [_c('TextButton', {
          attrs: {
            "icon": "mdi-download",
            "value": _vm.$t('campaigns.id.wowLinkListDownload')
          },
          on: {
            "click": function click($event) {
              return _vm.downloadGiftLinks();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "item.desiredArrivalOrIssueDate",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-body-1"
        }, [_vm._v(_vm._s(_vm.$date(_vm.shippingDate)))])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };