/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=060414c9"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VerticalTable: require('/code/src/components/table/VerticalTable.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,ShippingStatusTag: require('/code/src/components/widgets/ShippingStatusTag.vue').default,LinkText: require('/code/src/components/text/LinkText.vue').default,OutlinedTable: require('/code/src/components/table/OutlinedTable.vue').default,TouchSummary: require('/code/src/components/widgets/TouchSummary.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default})
